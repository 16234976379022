$font-family-base:  "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
"Helvetica Neue", sans-serif;

// global
@import "~bootstrap/scss/bootstrap";
@import "react-datepicker/dist/react-datepicker.css";
@import "colors";

// components
@import "loader";
@import "navbar";
@import "alert";
@import "form-control";
@import "modal";
@import "profile";
@import "file-bucket";
@import "label";
@import "interface-control";
@import "high-contrast";
@import "button";
@import "tooltip";

// themes
@import "theme-rara";
@import "theme-mirko";

// pages
@import "main-page";

html {font-size: 62.5%;}
body {font-size: 14px;font-size: 1.4rem;}
h1 {font-size: 24px;font-size: 2.4rem;}
h6 {font-size: 16px;font-size: 1.6rem;}

a,
a:link,
a:visited,
a:hover,
.link {
  text-decoration: underline;

  &:focus {
    outline: 0.25rem solid $black;
    outline-offset: 0.25rem;
  }
}

a:hover {
  text-decoration: underline;
}
.form-switch {
  .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 stroke=%27%239C5C39%27 stroke-width=%270.4%27 fill=%27%23fff%27/%3e%3c/svg%3e");

    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23000%27/%3e%3c/svg%3e");
    }
  }
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px $light_bg inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
  &:focus {
    box-shadow:  none;
    outline: 0.25rem solid $black;
  }
}

input[type='file'] {
  &.special-rights-file {
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: none;
  }
}

.special-rights-file-label-active {
  box-shadow:  none;
  outline: 0.25rem solid $black;
}

.special-rights-file-label {
  font-size: 1.6rem !important;
  cursor: pointer;
  padding: 0.8rem 2.4rem;
  border-width: 0;
  font-weight: 500;
  border-radius: 2rem;

  &>img {
    margin-right: 5px;
    height: 25px;
  }
  @media screen and (max-width: 922px) {
    display: flex;
    justify-content: center;
  }
}

.profile-img {
  content: url("../assets/profile-icon.svg")
}

.master-logo {
  &--rara {
    content: url("../assets/rara-logo.svg")
  }
  &--rara-mobile {
    content: url("../assets/rara-mobile-logo.svg")
  }
  &--mirko {
    content: url("../assets/mirko-logo.svg")
  }
  &--mirko-mobile {
    content: url("../assets/mirko-mobile-logo.svg")
  }
}

.increased-word-spacing {
    word-spacing: 1.5rem;
}

.increased-line-spacing {
    line-height: 3rem;
}

.id-card-logo {
  content: url("../assets/id-card-logo.svg");
}

.mobile-id-logo {
  content: url("../assets/mobile-id-logo.svg");
}

.smart-id-logo {
  content: url("../assets/smart-id-logo.svg");
}
.har-id-logo {
  content: url("../assets/harid-logo.svg");
}
.password-login-logo {
  content: url("../assets/password-login-logo.svg");
}
