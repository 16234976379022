.ra-modal {
  .modal-dialog {
	@media screen and (max-width: 922px) {
		/* Accommodate bottom navbar in mobile view*/
		margin-bottom: 72px;
	}

	.modal-content {
        border-radius: 0;

        .modal-header {
            padding-right: 64px;
            padding-left: 64px;
            border-bottom-width: 0;
        }

        .modal-body {
            padding-right: 64px;
            padding-left: 64px;
        }

        .modal-footer {
            padding-right: 64px;
            padding-left: 64px;
            border-top-width: 0;
            padding-bottom: 24px;
        }
    }
  }
}
