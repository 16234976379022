.tooltip {
  font-size: 16px;
  font-weight: 500;
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 200%;
  left: -80%;
  margin-left: -60px;
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
