.file-bucket {
  display: flex;
  flex-direction: column;

  span {
    margin-right: 10px;
    text-decoration: underline;
  }
}

.file-list {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
