.alert {
	&.page-alert {
		border-radius: 0;

		svg {
			vertical-align: -10%;
			margin-right: 8px;
		}
	}

	&.alert-info {
		background-color: $rara_lightblue;
		border-color: $rara_lightblue;
		color: $darkgrey;
		line-height: 20px;
		padding-top: 22px;
		padding-bottom: 22px;
		border-width: 0;

		@media screen and (min-width: 992px) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
}
