.label-wrapper {
  &>div {
    display: block;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

}

.label-container {
  border-radius: 15px;
  padding: 3px 10px;

  &--success {
    background-color: $accent_light;
  }

  &--error {
    background-color: $light_red;
  }
}
