.btn {
  font-size: 1.6rem;
  padding: 8px 24px;
  border-width: 0;
  font-weight: 500;
  border-radius: 20px;

  &:focus {
    box-shadow:  none;
    outline: 0.25rem solid $black;
  }
}

.btn-link {
  color: $black;
  font-weight: 500;
  text-decoration: none;
}
