.navbar {
  background-color: $nav_white;
  height: 80px;

  .navbar-nav {
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    height: 100px;
  }

  .navbar-brand img {
    height: 34px;

    @media screen and (min-width: 992px) {
      height: 42px;
    }
  }

  // to make sure that when increasing
  // font the nav bar will not change its size
  .container {
    padding: 0 7.5px;
  }

	.lang-menu {
		font-size: 15px;
		font-weight: 400;
		color: $black;

		.nav-link {
            padding: 5px;
			&:hover {
				text-decoration: underline;
			}

			img {
				width:auto;
			}
		}

    .accessability-img {
      content: url("../assets/accessability.svg");
    }

		.dropdown-toggle::after {
			display: none;
		}

		.dropdown-menu {
			right: 0;
			left: auto;
		}
	}

	.cur-lang {
		font-size: 15px;
		font-weight: 400;
		color: $black;
	}
}
