@import "colors";

$loader-bg: rgba(#000000, 60%);

$clock-size: 16rem;
$clock-arm-height: 6rem;
$clock-arm-height-short: 5rem;

.loader {
  &-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $loader-bg;
    z-index: 10000;
  }

  &-clock {
    position: relative;
    height: $clock-size;
    width: $clock-size;
    border-radius: 50rem;
    background-color: rgba($light, 50%);

    &-alt {
      top: 13%;
      left: -36%;
      position: absolute;
      background-color: rgba($primary, 40%);

      .loader-arm,
      .loader-middle {
        background-color: $rara_primary;
      }
    }
  }

  &-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50rem;
    background-color: $light;
    width: 1rem;
    height: 1rem;
  }

  &-arm {
    position: absolute;
    border-radius: 3px;
    background-color: $light;
    z-index: 1000033;
    width: 0.5rem;
    height: $clock-arm-height;
    transform-origin: 50% 100%;
    top: calc(8rem - #{$clock-arm-height});
    left: 77px;
    animation: 1.5s spin infinite linear;

    &-short {
      left: 7.8rem;
      height: $clock-arm-height-short;
      top: calc(8rem - #{$clock-arm-height-short});
      animation: 18s spin infinite linear;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

$loader-big-size: 676px;

.loader {
  &-wrap {
    position: relative;
  }
  &-big {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: $loader-big-size;
    height: $loader-big-size;
    border-radius: 50rem;
    background-color: $white;
    overflow: hidden;

    img {
      width: 615px;
    }
  }

  &-deco {
    width: 440px;
    height: 440px;
    position: absolute;
    background-color: rgba($primary, 30%);
    border-radius: 50rem;
    top: 18%;
    left: -20%;
  }
}

#loader {
    display: none;
}
