.profile {
  display: flex;

  &-info {
    word-break: break-word;
    margin-left: 12px;
    font-size: 1.6rem;

    &-name {
      font-size: 2rem;
      font-weight: 600;

      .font-weight-normal {
        font-weight: 500;
      }
    }
  }
}

.profile-section-mobile {
  display: flex;
  flex-direction: row;
}

.profile-info-name-mobile {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  margin-left: 10px;

  .font-weight-normal {
    font-weight: 500;
  }

}
