.theme-rara {
  input {
    &:focus {
      box-shadow:  none;
      outline: 0.25rem solid $black;
    }
  }

	.btn-primary {
		background-color: $rara_primary;
		color: $rara_primary_text;

		&:hover {
			background-color: $rara_primary_hover;
		}
	}

	.btn-secondary {
		background-color: $rara_secondary;
		color: $rara_secondary_text;

		&:hover {
			background-color: $rara_secondary_hover;
		}

		svg {
			fill: $rara_secondary_text;
		}
	}

	.btn-link {
		&:hover {
			color: $rara_primary_hover;
		}

		&:focus {
			box-shadow:  none;
			outline: 0.25rem solid $black;
		}
	}

  .special-rights-file-label {
		background-color: $rara_primary;
		color: $rara_primary_text;

    @media screen and (max-width: 922px) {
      display: flex;
      justify-items: center;
    }
  }

	a,
	a:link,
	a:visited,
	a:hover,
	.link {
		color: $mirko_brown;
	}

  .nav-link {
    color: $black !important;
    text-decoration: none;
	}

	.invalid-feedback {
		color: $error_text;
	}

	.form-control.is-invalid {
		border-bottom-color: $error_text;
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23F4633A%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23F4633A%27 stroke=%27none%27/%3e%3c/svg%3e");
	}

	.form-check-input {
		border: 0.8px solid $rara_primary;

		&:checked {
			background-color: $rara_primary;
		}

		&.is-invalid {
			border: 0.8px solid $error_text;

			&:checked {
				background-color: $error_text;
			}
		}
	}

	.form-switch {
		.form-check-input {
			border-color: $rara_primary;

			&:checked {
				background-color: $accent_light;
				border-color: $accent_light;
			}
		}
	}
}
