
.form-control {
	background-color: $light_bg;
	font-size: 1.4rem;
	border-radius: 0;
	border-top: 0 solid #000000;
	border-left: 0 solid #000000;
	border-right: 0 solid #000000;
	border-bottom: 1px solid #000000;
	line-height: 3rem;
	padding-bottom: 0.5rem;
}

.data-form {
	margin-top: 4rem;
	margin-bottom: 10rem;

	@media screen and (min-width: 992px) {
		margin-top: 100px;
	}

	h1 {
		font-size: 4rem;
		font-weight: 600;
    word-wrap: break-word;
		margin-bottom: 2.4rem;

		@media screen and (min-width: 768px) {
			font-size: 4rem;
		}

		@media screen and (min-width: 992px) {
			font-size: 6rem;
		}
	}

	h2 {
		font-size: 1.8rem;
		font-weight: 600;
		margin-bottom: 16px;

		@media screen and (min-width: 768px) {
			font-size: 2.6rem;
		}

		@media screen and (min-width: 992px) {
			font-size: 3rem;
		}

		.lighter {
			font-weight: 400;
		}

		span {
			margin-left: 8px;
		}
	}

	.prop {
		font-weight: 600;
	}
}

.form-label {
  position: relative;
	font-size: 1.4rem;
	margin-bottom: 4px;
}

.form-inline-text {
	font-size: 1.4rem;

	a {
		font-weight: 600;
	}
}
.form-inline-list {
	font-size: 1.4rem;
	list-style-type: none;
	padding: 0;
	margin: 0;
	li {
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 8px;
	}
}

.local-error {
	font-size: 1.4rem;
	color: $error_text;
}

.form-check-inline {
	.form-check-input {
		height: 20px;
		width: 20px;
		border-radius: 0;
	}
}


.form-select,
.form-control {
	background-color: $light_bg;
	font-size: 1.4rem;
	border-radius: 0;
	border-top: 0 solid #000000;
	border-left: 0 solid #000000;
	border-right: 0 solid #000000;
	border-bottom: 1px solid #000000;
	line-height: 3rem;
	padding-bottom: 5px;
}

.styled-container {
	//padding-bottom: 5px;

	.select__control,
	.styled__control {
		background-color: $light_bg;
		font-size: 1.4rem;
		border-radius: 0;
		border-top: 0 solid #000000;
		border-left: 0 solid #000000;
		border-right: 0 solid #000000;
		border-bottom: 1px solid #000000;
		line-height: 2.80rem;
		box-shadow: none;

    &--is-focused {
      outline: 0.25rem solid $black !important;
      outline-offset: 0.25rem !important;
    }

		.styled__single-value {
			padding-top: 2px;
			padding-bottom: 2px;
			margin-top: 2px;
			margin-bottom: 2px;
		}
	}

	.select__control {
		//line-height: 27px;
		.select__multi-value__label {
			font-size: 1.4rem;
		}
	}

	&.is-invalid {
		.styled__control {
			border-bottom: 1px solid $error_text;
		}
	}
}

.react-datepicker-wrapper {
  display: block;

	input {
		width: 100%;
		background-color: $light_bg;
		font-size: 1.4rem;
		border-radius: 0;
		border-top: 0 solid #000000;
		border-left: 0 solid #000000;
		border-right: 0 solid #000000;
		border-bottom: 1px solid #000000;
		line-height: 3rem;
		padding: 6px 12px 5px 12px;

		&.is-invalid {
			border-bottom: 1px solid $error_text;
		}
	}
}

.form-switch {
	.form-check-input {
		line-height: 2rem;
		height: 2rem;
		width: 3.6rem;
		margin-right: 1rem;
		border-color: $grey_light;
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 stroke=%27%2393ABB2%27 stroke-width=%270.4%27 fill=%27%23fff%27/%3e%3c/svg%3e");
		border-radius: 1rem 1rem;

		&:checked {
			background-color: $accent_light;
			border-color: $accent_light;
			background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23000%27/%3e%3c/svg%3e");
		}
	}
}

.form-check-label {
	font-size: 1.4rem;
  display: flex;
}

.form-buttons {
	text-align: right;

	button {
		margin-left: 1.6rem;

    @media screen and (max-width: 922px) {
      margin-left: 0;
      margin-bottom: 1rem;
    }
	}

	@media screen and (max-width: 922px) {
    display: flex;
    flex-direction: column;
	}
}

.form-buttons-resp {
	text-align: left;

	@media screen and (min-width: 768px) {
		text-align: right;
	}


	button {
		margin-right: 16px;
		margin-left: 0;

		@media screen and (min-width: 768px) {
			margin-right: 0;
			margin-left: 16px;
		}
	}
}

.buttons-spacer {
	button {
		margin-left: 8px;
		margin-right: 8px;
	}
}

.req-indic {
  position: absolute;
	color: red;
  font-size: 2.5rem;
  top: -0.8rem;
}

button.btn-icon-left {
	svg {
		margin-right: 6px;
		height: 16px;
		width: 16px;
	}
}

.form-inline-text {
	label {
		display: inline;

		@media screen and (max-width: 922px) {
      display: inline-grid;
		}
	}
}

.text-right {
	text-align: right;
}
