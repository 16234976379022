.interface-control-wrapper {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px;

  .interface-control {
    position: relative;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &--mobile {
    display: flex;
    width: 100vw;
    justify-content: space-around;
    position: fixed;
    z-index: 100000;
    bottom: 0;
    left: 0;
    border-radius: 0;
    padding: 20px;
    background: $nav_white;

    img {
      width: 30px;
    }
  }

  .toggle-contrast-img {
    content: url("../assets/change-contrast.svg");
  }

  .toggle-word-spacing-img {
    content: url("../assets/word-spacing.svg");
  }

  .change-line-height-img {
    content: url("../assets/hamburger-control.svg");
  }

  .increase-font-img {
    content: url("../assets/plus-control.svg");
  }
  .decrease-font-img {
    content: url("../assets/minus-control.svg");
  }
  .reset-settings-img {
    content: url("../assets/reset-control.svg");
  }
}


.interface-control-wrapper--mobile {

  .toggle-contrast-img {
    content: url("../assets/change-contrast.svg");
  }

  .change-line-height-img {
    content: url("../assets/hamburger-control.svg");
  }

  .increase-font-img {
    content: url("../assets/plus-control.svg");
  }
  .decrease-font-img {
    content: url("../assets/minus-control.svg");
  }
  .reset-settings-img {
    content: url("../assets/reset-control.svg");
  }
}
