
// general
$black: #000000;
$rara_lightblue: #D0E1F0;
$darkgrey: #1B1D29;
$light_bg: #F3F3F3;
$error_text: #F4633A;
$grey_light: #93ABB2;
$accent_light: #D3ECE4;
$light_red: #FFE2E2;
$nav_white: #F8F9FA;

// rara
$rara_primary: #090688;
$rara_primary_text: #FFFFFF;
$rara_primary_hover: #2926AA;
$rara_secondary: #D0E1F0;
$rara_secondary_text: #1B1D28;
$rara_secondary_hover: #C0D1E0;

// mirko
$mirko_primary: #FFC306;
$mirko_primary_text: #1B1D29;
$mirko_primary_hover: #E6B005;
$mirko_primary_text_hover: #4B4D69;
$mirko_secondary: #1B1D29;
$mirko_secondary_text: #FFFFFF;
$mirko_secondary_hover: #3E404D;
$mirko_brown: #9C5C39;

// high-contrast
$high_contrast_primary: #000000;
$high_contrast_accent: #FFFF00;
