.high-contrast {
  background-color: $high_contrast_primary !important;
  color: $high_contrast_accent !important;

  /* Change autocomplete styles in WebKit */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    //border: 1px solid green;
    //-webkit-text-fill-color: $black;
    -webkit-text-fill-color: $high_contrast_accent !important;
    -webkit-box-shadow: 0 0 0 30px $high_contrast_primary inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-internal-autofill-selected {
    background-color: $high_contrast_primary !important;
    color: $high_contrast_accent !important;
  }

  input {
		&:focus {
			box-shadow:  none;
			outline: 0.25rem solid $high_contrast_accent;
		}
  }

  .navbar {
    background-color: $high_contrast_primary;
  }

  .nav-link {
    &:link,
    &:visited,
    &:hover {
      color: $high_contrast_accent;
    }
		&:focus {
			box-shadow:  none;
			outline: 0.25rem solid $high_contrast_accent;
		}

    text-decoration: none;
  }

  .accessability-img {
    content: url("../assets/accessability-yellow.svg") !important;
  }

	.btn-primary {
		background-color: $high_contrast_accent;
		color: $high_contrast_primary;

		&:focus {
			box-shadow:  none;
			outline: 0.25rem solid $high_contrast_accent;
		}
	}

  .btn-secondary {
		background-color: $high_contrast_accent;
		color: $high_contrast_primary;

		&:focus {
			box-shadow:  none;
			outline: 0.25rem solid $high_contrast_accent;
		}
  }

  .btn-link {
    color: $high_contrast_accent;

		&:focus {
			box-shadow:  none;
			outline: 0.25rem solid $high_contrast_accent;
		}
  }

	a,
	a:link,
	a:visited,
	a:hover,
	.link {
		color: $high_contrast_accent;

		&:focus {
			box-shadow:  none;
			outline: 0.25rem solid $high_contrast_accent;
		}
	}

	.form-control {
    color: $high_contrast_accent;
    background-color: $high_contrast_primary;
    border-top: 1px solid $high_contrast_accent;
    border-left: 1px solid $high_contrast_accent;
    border-right: 1px solid $high_contrast_accent;
    border-bottom: 2px solid $high_contrast_accent;
  }

  .react-datepicker__input-container input {
    color: $high_contrast_accent;
    background-color: $high_contrast_primary;
    border-top: 1px solid $high_contrast_accent;
    border-left: 1px solid $high_contrast_accent;
    border-right: 1px solid $high_contrast_accent;
    border-bottom: 2px solid $high_contrast_accent;
  }

  // address field
  .styled-container {
    .select__control,
    .styled__control {
      background-color: $high_contrast_primary;
      border-top: 1px solid $high_contrast_accent;
      border-left: 1px solid $high_contrast_accent;
      border-right: 1px solid $high_contrast_accent;
      border-bottom: 2px solid $high_contrast_accent;

      &--is-focused {
        box-shadow:  none;
        outline: 0.25rem solid $high_contrast_accent !important;
      }

      .styled__indicator-separator {
        background-color: $high_contrast_accent;
      }

      .styled__dropdown-indicator {
        color: $high_contrast_accent;
      }

      .styled__clear-indicator {
        color: $high_contrast_accent;
      }

      .styled__input-container {
        color: $high_contrast_accent;
      }
      .styled__single-value {
        color: $high_contrast_accent;
      }
    }
  }
  .styled-container {
    .styled__menu .styled__menu-list {
      background-color: $high_contrast_primary;

      .styled__option--is-focused {
        background-color: transparent;
        border: 1px solid $high_contrast_accent;
      }
    }
  }

  .form-switch {
    .form-check-input {
      border-color: $high_contrast_accent;
      background-color: $high_contrast_primary;

      &:checked {
        background-color: $high_contrast_accent;
        border-color: $high_contrast_accent;
      }
    }
  }

  .form-switch {
    .form-check-input {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 stroke=%27%23ffff00%27 stroke-width=%270.4%27 fill=%27%23000%27/%3e%3c/svg%3e");

      &:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23000%27/%3e%3c/svg%3e");
      }
    }
  }

  .form-check-input {
    background-color: $high_contrast_primary;
    border: 1px solid $high_contrast_accent;
  }

  .label-container {
    &--success {
      background-color: $high_contrast_primary;
      border: 1px solid $high_contrast_accent;
    }

    &--error {
      background-color: $high_contrast_primary;
      border: 1px solid $high_contrast_accent;
    }
  }

  .special-rights-file-label-active {
    box-shadow:  none;
    outline: 0.25rem solid $high_contrast_accent;
  }

  .special-rights-file-label {
		background-color: $high_contrast_accent;
		color: $high_contrast_primary;
  }

  .profile-img {
    content: url("../assets/profile-icon-contrast.svg")
  }

  .master-logo {
    &--rara {
      content: url("../assets/rara-logo-yellow.svg")
    }
    &--rara-mobile {
      content: url("../assets/rara-mobile-logo-yellow.svg")
    }
    &--mirko {
      content: url("../assets/mirko-logo-yellow.svg")
    }
    &--mirko-mobile {
      content: url("../assets/mirko-mobile-logo-yellow.svg")
    }
  }
  
  .interface-control {
		&:focus {
			box-shadow:  none;
			outline: 0.25rem solid $high_contrast_accent;
		}
  }

  .interface-control-wrapper {
    background-color: $high_contrast_primary;
    border: 1px solid $high_contrast_accent;
  }
  .interface-control-wrapper--mobile {
    border-top: 1px solid $high_contrast_accent;
    background-color: $high_contrast_primary;
  }

  .toggle-contrast-img {
    content: url("../assets/change-contrast-yellow.svg");
  }

  .toggle-word-spacing-img {
    content: url("../assets/word-spacing-yellow.svg");
  }

  .change-line-height-img {
    content: url("../assets/hamburger-control-yellow.svg");
  }
  .increase-font-img {
    content: url("../assets/plus-contrast.svg");
  }
  .decrease-font-img {
    content: url("../assets/minus-control-yellow.svg");
  }
  .reset-settings-img {
    content: url("../assets/reset-contrast.svg");
  }

  .tooltip {
    border: 1px solid $high_contrast_accent;
    color: $high_contrast_accent;

    &::after {
      border-color: transparent transparent yellow transparent;
    }
  }

  .id-card-logo {
    content: url("../assets/id-card-logo-yellow.svg");
  }

  .mobile-id-logo {
    content: url("../assets/mobile-id-logo-yellow.svg");
  }

  .smart-id-logo {
    content: url("../assets/smart-id-logo-yellow.svg");
  }
  .har-id-logo {
    content: url("../assets/harid-logo-yellow.svg");
  }
  .password-login-logo {
    content: url("../assets/password-login-logo-yellow.svg");
  }

  .alert-info {
		background-color: $high_contrast_primary;
    border: 1px solid $high_contrast_accent;
		color: $high_contrast_accent;
  }

  .alert-warning {
		background-color: $high_contrast_primary;
    border: 1px solid $high_contrast_accent;
		color: $high_contrast_accent;
  }

  .remove-file-btn {
    background-color: $high_contrast_accent;
		color: $high_contrast_primary;
  }

  .ra-modal {
    .modal-content {
      background-color: $high_contrast_primary;
      border: 1px solid $high_contrast_accent;
    }

    .modal-header .btn-close {
      background-color: $high_contrast_accent;
      opacity: 1;
    }
  }

  .select__multi-value {
    background-color: $high_contrast_primary;
    border: 1px solid $high_contrast_accent;

    &__label {
      color: $high_contrast_accent;
    }
  }

  .select__indicator {
    color: $high_contrast_accent;
  }

  .select__indicator-separator {
    background-color: $high_contrast_accent;
  }

  .select__menu {
    background-color: $high_contrast_primary;

    .select__menu-list .select__option--is-focused {
      background-color: transparent;
      border: 1px solid $high_contrast_accent;
    }
  }
}
